@import '~antd/dist/antd.css';

.App {
  width: 100%;
  height: 100%;
}
.nice-sider {
  min-width: 280px !important; /* need !important to overwrite css in .less file style antd */
  max-width: 280px !important; /* same */
  flex: 0 0 280px !important; /* same */
  background: #2E84D6;
  padding: 16px;
}