* {
  box-sizing: border-box;
}

:root {
  --font-family: 'Noto Sans', sans-serif;
  --font-size: 1rem;
  --color-primary-b600: #186bbb; // Primary
  --color-primary-link: #108feb;

  --color-green-g400: #52bd94;
  --color-green-g500: #429777;

  --color-red-r500: #d14343;

  --color-yellow-y400: #ffb020;

  --color-error-message: #d56565;

  --color-neutral-n900: #101840;
  --color-neutral-n800: #474d66;
  --color-neutral-n700: #696f8c;
  --color-neutral-n600: #8f95b2;
  --color-neutral-n500: #c1c4d6;
  --color-neutral-n400: #d8dae5;
  --color-neutral-n300: #e6e8f0;
  --color-neutral-n200: #edeff5;
  --color-neutral-n300: #e6e8f0;
  --color-neutral-n100: #f4f6fa;
  --color-neutral-n75: #f9fafc;
  --color-neutral-n0: #ffffff;
}

/*-------- Essentials  --------*/
.hide {
  display: none !important;
  transition: all 0.15s ease-in;
}

.show {
  display: block !important;
  transition: all 0.15s ease-in;
}

/*-------- Overall Layout --------*/
html,
body,
#root {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 1px;

  font-family: var(--font-family) !important;
  font-size: var(--font-size);
  font-weight: 400;
  background-color: var(--color-background);
  color: var(--color-neutal-n900);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ant-form-item-label > label.ant-form-item-required::after {
    content: '*';
    color: var(--color-red-r500);
  }

  .ant-form-item-explain-error {
    color: var(--color-red-r500);
    line-height: 130%;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: var(--color-red-r500);
    box-shadow: none;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: none;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .esign-otp .ant-modal-content,
  .loan-thanks .ant-modal-content {
    border-radius: 0;
  }

  .esign-otp .ant-modal,
  .loan-thanks .ant-modal {
    max-width: 100%;
    overflow: hidden;
    margin: 0;
  }

  .esign-otp .ant-modal-content,
  .loan-thanks .ant-modal-content {
    min-height: 100vh;
  }

  .ant-progress-bg {
    background-color: var(--color-primary-b600);
    border-radius: 0;
  }

  .ant-picker-clear {
    display: none;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--color-primary-b600) !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: var(--color-primary-b600);
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: var(--color-primary-b600);
  }
}

body {
  button {
    background: var(--color-primary-b600);
    border: none;
    color: white;
    padding: 10px 0;
    border-radius: 4px;
  }

  /* Page common stylesheet */
  // fixed paged header global stylesheet
  .page-header {
    border: 1px solid var(--color-neutral-n100);
    padding: 12px 24px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  @media screen and (max-width: 576px) and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .send-question-modal .ant-modal {
      margin: 0;
    }
    .send-question-modal .ant-modal-content {
      height: 100vh;
      width: 100vw;
      right: 0;
      margin: 0;
      border-radius: 0;
    }
    .send-question-modal.ant-modal-centered {
      text-align: left;
    }
  }
}

@media screen and (max-width: 991px) {
}

// PC layout
@media screen and (min-width: 992px) {
  body .esign-otp .ant-modal {
    max-width: 384px;
  }

  body .loan-thanks .ant-modal {
    max-width: 486px;
  }

  body .loan-thanks .ant-modal-body {
    padding: 0;
  }

  body .esign-otp .ant-modal-body {
    padding: 0 0 24px;
  }

  body .esign-otp .ant-modal-content,
  body .loan-thanks .ant-modal-content {
    min-height: auto;
    border-radius: 8px;
  }
}

.esign-otp .ant-modal-content {
  border-radius: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.none-footer {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.none-label {
  .ant-form-item-label {
    label {
      &::after {
        display: none;
      }
    }
  }
}
